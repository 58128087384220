.nh-content{
    margin-top:4%;
}
.nh-content legend,
.nh-content h3{
    text-align: center;
    margin-top:20px;
}
.nh-content h3{
    margin-bottom: 50px;
}