.login-form{
    padding:12% 5%;
}
.login-form legend{
    text-align: center;
    border: 1px solid transparent;
    border-radius: 5px;
    color:rgb(150, 1, 1);
    background-color: rgba(241, 0, 0, 0.315);
}
.login-form .row{
    margin:20px 0;
}
.login-form-btn-wrap{
    text-align: center;
}