.casaacao-page-content{
    color:rgb(100,100,100);
}
.casaacao-page-content h3{
    font-size: 24px;
}
.casaacao-page-content p{
    margin:30px 0;
    font-size: 20px;
    text-align: justify;
}
.casaacao-logo-top{
    text-align: center;
}
.casaacao-logo-top img{
    height: 350px;
}
.casaacao_pillars_icon{
    width: 33.3%;
    text-align: center;
    margin:30px 0;
}
.casaacao_pillars_icon legend{
    font-size: 16px;
}
/*
cores casa ação insta:
Azul turquesa #5ce1e6
Azul água #00c2cb
Vermelho vivo #ff1616
Cinza-claro #e8e8e8
Cinza-escuro #c8c8c8
*/
.casaacao_pillars_icon:nth-child(even){
    color:#00c2cb
}
.casaacao-card{
    background-color: rgb(250,250,250);
    border:1px solid rgb(200,200,200);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding:20px 0;
    align-items: center;
}
.casaacao-card-qrcode img{
    margin:0 50px;
    height: 120px;
}
.casaacao-card-logo img{
    height: 210px;
}
.casaacao-card-qrcode{
    border-right: 1px solid rgb(220,220,220);
    
}
.casaacao-card-info a{
    font-size: 18px;
    font-weight: bold;
}
.casaacao-card-info a:visited{
    color:inherit;
}
.casaacao-card-info p{
    font-size: 14px;
}
/*
.main-menu, .hp-content-footer, .menu-options-parent{
    background-color: rgb(150,150,150);
}
.footer-infos-offer img{
    opacity: .6;
} */
#c-a-logo{
    opacity: 0;
    transition: all 4s;
}