.qfp-content{
    text-align: justify;
}
.qfp-content h3 {
    text-align: center;
    margin: 50px 0;
}

.qfp-pastores{
    margin-bottom:100px;
    text-align: center;
}
.qfp-pastores svg,
.qfp-pastores img{
    margin:10px 0 !important;
}
.qfp-pastores p{
    margin: 0;
}
.acao-em-acao-content a{
    color:rgb(250,250,250);
}
.qfp-pastores img{
    height: 250px;
    border-radius: 50%;
}
.qfp-pastores a{
    color:rgb(50,50,50);
}
.qfp-citacao{
    font-style: italic;
    text-align: center;
    margin: 2% 10%;
}
.qfp-btn{
    text-align: center;
    margin: 50px 0;
}