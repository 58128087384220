.main-menu{
    width: 100%;
    height:75px;
    display:flex;
    flex-direction: row;
    background: rgb(0,0,0);
    align-items: center;
    position: fixed;
    z-index: 1;
}
.main-menu div{
    width: 33.3%;
    text-align: center;
}

.menu-options-parent a{
    color:rgb(250,250,250);
}

.menu-button, .close-menu-button{
    color: rgb(250,250,250);
    background: none;
    border: none;
    height: 50px;
    width: 50px;
}
.menu-button:hover, .close-menu-button:hover{
    cursor: pointer;
}

.menu-options-parent{
    background: rgb(0,0,0);
    position: fixed;
    top:0;
    left: 0;
    z-index: 20;
    padding: 35px 10px !important;
    height: 100%;
    width: 40% !important;
}

.close-menu-button{
   position: fixed;
   top: 10px; 
   left: 0;
}

.menu-options{
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
    width: 100% !important;
    color: rgb(250,250,250);
}
.menu-options ul{
    text-align: left;
    list-style: none;
    min-width: 500px;
}
.menu-options ul li{
    margin:5px 0;
}
.menu-options ul li ul{
    margin:2px 0;
    width: 500px;
    font-weight: normal;
}
.menu-options-social{
    margin-top: 50px;
    font-size: 18px;
    font-weight: normal;
    width: 100% !important;
}
.other-actions{
    text-align:right !important;
}