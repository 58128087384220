.hp-content-section1{
    background: rgb(0,0,0);
    color: rgb(250,250,250);
}
.section1-title{
    margin: 60px 0;
    font-size: 40px;
}
.section1-mvv-child{
    text-align: justify;
    padding-top: 10px;
    padding-bottom: 10px;
}
.section1-mvv-child:nth-child(even){
    padding-left: 30px;
    padding-right: 30px;
}