.section4-about-wrap{
    flex-basis: 2;
    text-align: center;
}
.section4-about-item{
    margin:30px 0;
}
.section4-about-icon{
    color:rgb(50,50,50);
    transition: all .5s;
}
.section4-about-icon:hover{
    color:rgb(230,230,230);
    cursor: pointer;
}
.section4-about-item p{
    font-size: 20px;
}
.about-links-item{
    width: 100%;
    height: 70px;
    transition: all .5s;
    padding:10px 5px;
    border-radius: 10px;
}
.about-links-item a{
    color:rgb(50,50,50);
    font-size: 20px;
    transition: all .1s;
}
.about-links-item a:hover{
    font-weight: bold;
}
.about-links-item:hover{
    height: 80px;
    width: 105%;
    padding:25px 30px;
    background-color: rgb(245, 245, 245);
}