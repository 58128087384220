.timeline{
    align-items: center;
}
.timeline-l{
    border-right:1px solid;
    margin-left: 1px;
}
.timeline-r{
    border-left:1px solid;
}
.timeline-content{
    border: 1px solid rgb(130,130,130);
    border-radius: 10px;
    padding:2% 4%;
    transition: all .5s;
}
.timeline-content:hover{
    padding:4% 4% 8% 4%;
    cursor: pointer;
}
.timeline-header{
    display: flex;
    flex-direction: row;
}
.timeline-header legend{
    margin: 0 10px;
}
.timeline p{
    margin-bottom: 0 !important;
}
.timeline-legend{
    transition: all .5s;
    opacity: 0;
}