.welcome-content .row{
    margin-top:50px;
}
.welcome-content h1{
    text-align: center;
    margin-bottom: 50px;
}
.welcome-content p{
    text-align: justify;
}
.welcome-content p a{
    font-weight: bold;
    color: black;
    text-decoration: underline;
}
.welcome-content h3{
    margin-bottom: 30px;
}
.welcome-content table{
    text-align: center;
}
.welcome-content tr{
    align-content: center;
}
.welcome-content .welcome-video-wrap iframe{
    width: 100%;
    height: 500px;
    margin-top: 50px;
}