.main-button{
    background:transparent;
    color: rgb(255,255,255);
    font-weight: 'bold';
    height:50px;
    border: 1px solid rgb(250,250,250);
    border-radius: 5px;
    transition: all .5s;
}
.main-button:hover{
    background: rgb(250,250,250);
    color: rgb(0,0,0);
    cursor: pointer;
}