.moodal{
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .9);
    z-index: 20;
}
.moodal-content{
    background: rgb(250,250,250);
    height: 90%;
    width: 60%;
    margin:3% 20%;
    z-index: 21;
    padding:30px 20px;
    border-radius: 20px;
}
.moodal-close svg{
    cursor: pointer;
}