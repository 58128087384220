::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: rgb(0,0,0);
}
::-webkit-scrollbar-thumb {
  background: rgb(50,50,50);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(30,30,30);
  cursor: pointer;
}

body {
  overflow-x: hidden;
}
a,
a:active{
  text-decoration: none;
}
legend{
  font-size: 14px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.section{
  font-size: 18px;
  padding: 2% 8% 4% 8%;
  overflow-x: hidden;
}
.content{
  padding-top: 75px;
  overflow-x: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.main-menu-logo{
  filter:invert(100%);
  height: 60px;
}
.align-left{
  text-align: left !important;
}
.align-right{
  text-align: right !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*mobile*/
@media only screen and (max-width: 800px) {
  .graters{
    display: none;
  }
  .section2-tbl{
      display: none;
  }
  .events-icon{  
    width: 30% !important;
  }
  legend{
    font-size: 12px;
  }
  /*Main Header*/
  .menu-button{
    margin-left: 15px;
  }
  .menu-button .fa-xl{
    font-size: 1.3em;
  }
  .main-menu-logo{
    height: 45px;
  }
  .main-button{
    font-size: 10px;
    height: 40px;
  }
  .other-actions{
    text-align:center !important;
  }
  .menu-options-parent{
    width: 100% !important;
  }
  .menu-options{
    font-size: 14px;
  }
  /*Home*/
  .section1-title{
    font-size: 22px;
  }
  .section1-mvv-child p{
    font-size: 14px;
  }
  .events-day{
    font-size: 16px;
  }
  .events-mon{
    font-size: 14px;
  }
  .events-desc p{
    font-size: 16px;
  }
  .events-desc legend{
    font-size: 12px;
  }
  /*Main Footer*/
  .footer-head img {
    height:80px;
  }
  .social-icons-wrap .social-icons{
    font-size: 1.5em !important;
    margin-top: 10px;
  }
  .footer-infos-desc{
    margin: 30px 0;
  }
  .footer-infos h3, .footer-infos p{
    font-size: 14px;
  }
  /*Timeline*/
  .timeline-header svg{
    font-size: .7em;
  }
  .timeline-content h3{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .timeline-content p{
    font-size: 14px;
  }
  .timeline-content:hover{
    padding:4% 4% 50% 4%;
  }
  /*Ação em Ação*/
  .acao-em-acao-main-logo img{
    height: 80px;
    margin: 100px 0 50px 0;
  }
  .acao-em-acao-desc p{
    font-size: 16px;
  }
  /*Evangelismos*/
  .ev-evangelismos-desc h3{
    font-size: 16px;
    text-align: center;
  }
  .ev-evangelismos-desc p{
    font-size: 14px;
  }
  .ev-invasao{
    margin-bottom: 30px;
  }
  /*Casa Ação*/
  .casaacao-logo-top img{
    height: 220px;
  }
  .casaacao-page-content h3{
    font-size: 18px;
  }
  .casaacao-page-content p{
    font-size: 16px;
  }
  .casaacao_pillars_icon{
    margin: 25px 0 0 0;
  }
  .casaacao_pillars_icon svg{
    font-size: 1.5em;
  }
  .casaacao-card-qrcode img{
    height: 80px;
    margin-top: 15px !important;
  }
  .casaacao-card-logo img{
    height: 110px;
  }
  .casaacao-card-qrcode img,
  .casaacao-card-logo img{
    margin: 0;
  }
  .casaacao-card-info p{
    text-align: center;
    font-size: 14px;
    margin: 20px 0 0 0;
  }
  /*Livros*/
  .livros-premsg{
    font-size: 16px;
  }
  .livros-download{
    margin-bottom: 30px;
  }
  .offer-modal-desc p{
    font-size: 12px;
  }
  .offer-modal-desc img{
    height: 8em;
  }
  /*Modais*/
  .moodal-content{
    width: 90%;
    height: 70%;
    margin: 15% 5%;
  }
  /*?*/
  .jta-desc{
    margin-top: 100px;
    font-size: 18px;
  }
  .jta-desc i{
    font-size: 26px;
    font-weight: bold;
  }
}
/*graters*/
@media only screen and (min-width: 800px) {
  .mobile{
    display:none;
  }
  .section2-tbl-mobile{
      display: none;
  }
}
