.livros-content{
    text-align: center;
    padding:5% 0;
}
.livros-premsg{
    font-size: 20px;
}
.livros-download-wrap {
    margin-top: 50px;
}
.livros-download img{
    transition: all .5s;
    height: 300px;
    margin-bottom: 25px;
    border-radius: 50%;
}
.livros-download img:hover{
    border-radius: 5%;
    cursor: pointer;
}
.offer-modal-wrap{
    height: 100%;
    align-items: center;
}
.offer-modal-desc{
    padding: 0 20%;
}
.offer-modal-desc p{
    margin-bottom: 20px;
    font-size: 20px;
}