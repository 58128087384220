.sec-button{
    min-height:30px;
    min-width: 110px;
    padding:0 10px;
    border: 1px solid rgb(130,130,130);
    border-radius: 5px;
    transition: all .5s;
    background:transparent;
    color: rgb(0,0,0);
}
.sec-button:hover{
    background:rgb(240,240,240);
    color: rgb(130,130,130);
    cursor: pointer;
}