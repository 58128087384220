.ev-main-desc{
    text-align: center;
}
.ev-invasao{
    margin-bottom: 100px;
}
.ev-evangelismos{
    text-align: justify;
}
.ev-evangelismos p{
    margin: 20px 0;
}
.ev-evangelismos-desc{
    padding:0 10px;
    align-items: center;
}
.ev-invasao-logo{
    text-align: center;
}
.ev-invasao-logo img{
    width: 50%;
}